import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { AuthService } from '../../../services/auth/auth.service';

@Component({
  selector: 'pp-all-users',
  templateUrl: './all-users.component.html',
  styleUrls: ['./all-users.component.scss']
})
export class AllUsersComponent implements OnInit {
  patients: any;

  constructor(
    private dialog: MatDialog,
    private authService: AuthService,
    private dialogRef: MatDialogRef<AllUsersComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.patients = this.data.allUsers;
  }

  selectedPatient(patientid, externalid, orguid) {
    this.authService.setAuthToken(patientid, externalid, orguid);
    localStorage.setItem('token', patientid);
    localStorage.setItem('ref', externalid);
    
    this.dialogRef.close(this.data.iscalledonbookaptsignin);
  }


}
