import { Component, ViewEncapsulation, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { PaymentService } from '../../../services/payment/payment.service';

@Component({
  selector: 'pp-report-view',
  templateUrl: './report-view.component.html',
  styleUrls: ['./report-view.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ReportViewComponent implements OnInit {

  file: any = null;
  fileUrl: any = null;
  loading = true;

  constructor (
    private sanitizer: DomSanitizer,
    private snackbar: MatSnackBar,
    private paymentService: PaymentService,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) { }

  ngOnInit() {
    this.paymentService.viewReport(this.data).subscribe(res => {
      this.file = res;
      fetch(this.file.file).then(res => res.blob()).then(blob => {
        this.file.file = URL.createObjectURL(blob);
        this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.file.file);        
        this.loading = false;
      }, _ => {
        this.snackbar.open('File Error', '', {
          panelClass: ['pp-snackbar-err'],
          verticalPosition: 'top',
          horizontalPosition: 'right',
          duration: 3000
        });
        this.loading = false;
      });
    });
  }

  newTabClick() {
    window.open(this.file.file);
  }

  downloadClick() {
    const a = document.createElement('a');
    a.href = this.file.file;
    a.download = this.file.documentname;
    a.click();
  }

}
