import { Component, ViewEncapsulation, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatSnackBar } from '@angular/material';
import { SigninComponent } from '../signin/signin.component';
import { AuthService } from '../../../services/auth/auth.service';
import { Client, ClientInit, ClientService } from '../../../services/client/client.service';

@Component({
  selector: 'pp-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SignupComponent implements OnInit {

  signUpForm: FormGroup;
  patientSignUpForm: FormGroup;
  dobMaxDate: Date = new Date();
  tiltles: any[] = [];
  genders: any[] = [];
  loading: boolean = false;
  alreadyRegistered: boolean = false;
  client: Client = ClientInit;

  constructor (
    private fb: FormBuilder,
    private dialog: MatDialog,
    private snackbar: MatSnackBar,
    private authService: AuthService,
    private clientService: ClientService,
    private dialogRef: MatDialogRef<SignupComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) { 
    this.clientService.getClientDetails().subscribe(res => this.client = res.data || ClientInit);
  }

  ngOnInit() {
    this.patientSignUpForm = this.fb.group({
      patienttitleuid: ['', Validators.required],
      patientname: ['', Validators.required],
      patientgenderuid: ['', Validators.required],
      patientemail: ['', Validators.email],
      patientmobile: ['', Validators.required],
      patientdob: ['', Validators.required]
    });
    this.signUpForm = this.fb.group({
      titleuid: ['', Validators.required],
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      genderuid: ['', Validators.required],
      email: ['', Validators.email],
      mobile: ['', Validators.required],
      dob: ['', Validators.required]
    });
    this.authService.getTitle().subscribe(res => this.tiltles = res.dropdownlist);
    this.authService.getGender().subscribe(res => this.genders = res.dropdownlist);
  }

  onSubmit(data) {
    this.loading = true;
    data.orguid = this.data.orguid;
    this.authService.signUp(data).subscribe(res => {
      this.hideLoader();
      // this.dialogRef.close(null);
      this.alreadyRegistered = res.duplicate;
      if (!this.alreadyRegistered) {
        if (res.allowWithoutOTP) {
          this.authService.setAuthToken(res.patientId, res.externalid, res.orguid);
          this.dialogRef.close(res.patientId);
          return;
        }
        if(this.client.onlypatientname)
        this.signInClick(data.patientmobile);
        else 
        this.signInClick(data.mobile);
        this.snackbar.open('One Time Password (OTP) has been sent to your mobile, please enter the same here to signin', '', {
          panelClass: ['pp-snackbar-info'],
          verticalPosition: 'top',
          horizontalPosition: 'right',
          duration: 5000
        });
      }
    }, err => {
      this.hideLoader();
      this.snackbar.open(err.error.error, '', {
        panelClass: ['pp-snackbar-err'],
        verticalPosition: 'top',
        horizontalPosition: 'right',
        duration: 5000
      });
      // this.dialogRef.close(null);
    });
  }

  signInClick(mobile) {
    const dialog = this.dialog.open(SigninComponent, {
      panelClass: 'pp-sign-in',
      width: '500px',
      disableClose: true,
      data: {
        mobile: mobile,
        orguid: this.data.orguid
      }
    });
    dialog.afterClosed().subscribe(patientId => {
      if (patientId) {
        this.dialogRef.close(patientId);
      }
    });
  }

  hideLoader() {
    setTimeout(() => this.loading = false, 500);
  }

}