import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { ContactUsService } from '../../../services/contactus/contactus.service';

@Component({
  selector: 'pp-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ContactusComponent implements OnInit {

  contactus: string = null;
  loading: boolean = true;

  constructor(private contactusService: ContactUsService) {
    const orguid = localStorage.getItem('orguid');
    if (orguid) {
      this.contactusService.getContactUs(orguid).subscribe(res => {
        this.contactus = res.contactus;
        setTimeout(() => this.loading = false, 200);
      }, _ => setTimeout(() => this.loading = false, 200));
    } else {
      setTimeout(() => this.loading = false, 200);
    }
  }

  ngOnInit() {
  }

}
