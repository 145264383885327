import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  constructor(private http: HttpClient) { }

  public postFile(fileToUpload: File[], patientuid,orguid): Observable<any> {
    const formData: FormData = new FormData();
    Array.from(fileToUpload).forEach(f => formData.append('file', f))
    formData.append('orguid', orguid);
    formData.append('patientuid', patientuid);
    return this.http.post<Boolean>(`/api/patient/upload`, formData).pipe(
      map(res => res),
      catchError((e: any) => { return throwError(e) })
    )
  }

  /* If progress bar is needed in future usie this code in response [Future update] */
  // switch (res.type) {

  //   case HttpEventType.UploadProgress:
  //     const progress = Math.round(100 * res.loaded / res.total);
  //     return { status: 'progress', message: progress };

  //   case HttpEventType.Response:
  //     return res.body;
  //   default:
  //     return `Unhandled event: ${res.type}`;
  // }

  public getdocuments(reqpayload):Observable<any>{
    return this.http.post<Boolean>(`/api/uploaddocuments`,reqpayload).pipe(
      map(res => res),
      catchError((e: any) => { return throwError(e) })
    )
  }

  public deleteDocument(docID):Observable<any>{
    return this.http.put<Boolean>(`/api/uploaddocuments`,docID).pipe(
      map(res => res),
      catchError((e: any) => { return throwError(e) })
    )
  }

  public getDocument(docID):Observable<any>{
    return this.http.get<Boolean>(`/api/uploaddocuments/${docID}`).pipe(
      map(res => res),
      catchError((e: any) => { return throwError(e) })
    )
  }

  public getUploadRef():Observable<any>{
    return this.http.get<Boolean>(`/api/patientdocument/ref`).pipe(
      map(res => res),
      catchError((e: any) => { return throwError(e) })
    )
  }
}
