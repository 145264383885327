import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface Doctor {
  id: string;
  externalid: string;
  orguid: string;
  title: string;
  name: string;
  gender: string;
  qualification: string;
  primarydept: string;
  speciality: string;
  description: string;
  photo: string;
  location: string;
  organisation : string;
  companyname : string;
  departments : [];  
}

export const DoctorInit: Doctor = {
  id: null,
  externalid: null,
  orguid: null,
  title: null,
  name: null,
  gender: null,
  qualification: null,
  primarydept: null,
  speciality: null,
  description: null,  
  location: null,
  photo: null,
  organisation :null,
  companyname : null,
  departments : null
}

const getDoctor = (data): Doctor => {
  return {
    id: data._id || null,
    externalid: data.externalid || null,
    orguid: data.orguid && data.orguid.externalid || null,
    title: data.titleuid && data.titleuid.valuedescription || null,
    name: data.name || null,
    gender: data.genderuid && data.genderuid.valuedescription || null,
    qualification: data.qualification || null,
    primarydept: data.primarydept || null,
    speciality : data.specialtyuid && data.specialtyuid.valuedescription || null,
    description: data.description || null,
    location: data.location || null,
    photo: data.doctorimageuid && data.doctorimageuid.doctorphoto || null,
    organisation : data.orguid && data.orguid.name || null,
    companyname : data.companyname || null,
    departments : data.departments || null
  };
}

@Injectable({
  providedIn: 'root'
})
export class DoctorService {

  constructor(private http: HttpClient) { }

  getDepartment(orguid): Observable<any> {
    return this.http.get<any>(`/api/department/${orguid}`);
  }

  getOrganization(): Observable<any> {
    return this.http.post<any>(`/enterprise/doctor/getorganisationlist`, {});
  }

  getDoctorsList(data): Observable<Doctor[]> {
    return this.http.post<any>(`/api/doctor/search`, data).pipe(map(res => res.data.map(e => getDoctor(e))));
  }

  getDoctorPhoto(doctorId: string): Observable<any> {
    return this.http.get<any>(`/enterprise/doctor/getimagedetail/${doctorId}`);
  }

  getDoctorById(id: string): Observable<Doctor> {
    return this.http.get<any>(`/enterprise/doctor/getdoctordetail/${id}`).pipe(map(res => getDoctor(res.data)));
  }

  getDoctorTimeSlot(data): Observable<any> {
    return this.http.post<any>(`/api/doctor/timeslot`, data);
  }
}
