import { Component, ViewEncapsulation, OnInit } from '@angular/core';

@Component({
  selector: 'pp-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoadingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
