import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from '../components/home/home.component';
import { DashboardComponent } from '../components/dashboard/dashboard.component';
import { AuthGuardService } from '../services/auth-guard/auth-guard.service';
import { DoctorListComponent } from '../components/shared/doctor-list/doctor-list.component';

const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    canActivate: [AuthGuardService],
    data: { auth: true }
  },
  { 
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuardService],
    data: { auth: false }
  },
  { 
    path: 'dashboardview',
    component: HomeComponent,
    canActivate: [AuthGuardService],
    data: { auth: false }
  },
  {
    path: 'appointment',
    component: DoctorListComponent
  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class RoutingModule { }