import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { MatDialog } from '@angular/material';
import { UserIdleService } from 'angular-user-idle';

import { IdleTimeoutComponent } from '../../components/shared/idle-timeout/idle-timeout.component';
import { Client, ClientInit, ClientService } from '../client/client.service';

export interface User {
  patientId: string;
  refId: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor (
    private router: Router,
    private http: HttpClient,
    private dialog: MatDialog,
    private userIdle: UserIdleService,
    private clientService: ClientService
  ) { }

  startedTime = null;
  isIdelTimeoutPopupOpen: boolean = false;

  getOtp(mobileNo: number,captcha?:any): Observable<any> {
    return this.http.post<any>(`/framework/security/sendsigninotp`, { loginid: mobileNo, captcha });
  }

  signUp(data: any): Observable<any> {
    return this.http.post<any>(`/api/user/signup`, data);
  }

  signIn(mobileNo: number, otp: number): Observable<any> {
    return this.http.post<any>(`/framework/security/authenticate`, { loginid: mobileNo, otp: otp });
  }

  getTitle(): Observable<any> {
    return this.http.post<any>(`/framework/referencedomain/getdropdownlist`, { domaincode: 'TITLE' });
  }

  getGender(): Observable<any> {
    return this.http.post<any>(`/framework/referencedomain/getdropdownlist`, { domaincode: 'GENDER' });
  }

  setAuthToken(token: string, refId: string, orguid: string): void {
    localStorage.setItem('token', token || null);
    localStorage.setItem('ref', refId || null);
    localStorage.setItem('orguid', orguid || null);
    this.setOtherDetailsAfterLogin();
  }

  setOtherDetailsAfterLogin(): void {
    this.userIdle.stopWatching();
    const orguid = localStorage.getItem('orguid');
    if (!orguid) return;
    
    this.http.get<any>(`/framework/security/getppsetting/${orguid}`).subscribe(res => {
      if (!res || !res.ppSettings) return;
      this.setIdelTimeout(res.ppSettings.idletimeout)
    });
  }

  setIdelTimeout(idletimeout) {
    this.userIdle.stopWatching();
    if (idletimeout == null || idletimeout < 0) return;
    
    this.userIdle.setConfigValues({
       idle: idletimeout * 60, 
       timeout: 35, 
       ping: 0
    });
    this.userIdle.startWatching();
    this.startedTime = moment();

    // Start watching when user idle is starting.
    this.userIdle.onTimerStart().subscribe(() => {
      if (this.isIdelTimeoutPopupOpen || !this.isAuthenticated()) return;
      if (moment().diff(this.startedTime, "seconds") + 2 < (idletimeout * 60)) return;
      this.isIdelTimeoutPopupOpen = true;

      this.dialog.open(IdleTimeoutComponent, {
        panelClass: 'pp-sign-in',
        width: '520px',
        disableClose: true
      }).afterClosed().subscribe((isLogout) => {
        if (isLogout) {
          this.signOut();
        } else {
          this.userIdle.resetTimer();
        }
        this.dialog.closeAll();
        this.isIdelTimeoutPopupOpen = false;
      }, () => {
        this.dialog.closeAll();
        this.isIdelTimeoutPopupOpen = false;
      });
    });
    
    // Start watch when time is up.
    this.userIdle.onTimeout().subscribe(() => {
      this.dialog.closeAll();
      this.isIdelTimeoutPopupOpen = false;
      this.signOut();
    });
  }

  getUser(): User {
    return {
      patientId: localStorage.getItem('token') || null,
      refId: localStorage.getItem('ref') || null
    }
  }

  isAuthenticated(): boolean {
    const user = this.getUser()
    return user.patientId && user.refId ? true : false;
  }

  signOut(): void {
    localStorage.removeItem('token');
    localStorage.removeItem('ref');
    localStorage.removeItem('orguid');
    this.userIdle.stopWatching();

    this.clientService.getClientDetails().subscribe(res => {
      const client: Client = res.data || ClientInit;
      if (client.name === 'mgm') {
        this.router.navigateByUrl('appointment', { skipLocationChange: true });
      } else {
        this.router.navigateByUrl('home', { skipLocationChange: true });
      }
    }, _ => {
      this.router.navigateByUrl('home', { skipLocationChange: true });
    });
  }

  Verifysessiontoken(data:any): Observable<any> {
    return this.http.post<any>(`/framework/security/verifysessiontoken`,data);
  }

  Loadcaptcha(noise: any): Observable<any> {
    return this.http.post<any>(`/framework/security/loadcaptcha`, { noise });
  }

  getAppconfig(): Observable<any> {
    return this.http.get<any>(`/framework/security/getappconfig`);
  }
}
