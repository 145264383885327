import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { AuthService } from '../../../services/auth/auth.service';
import { Patient, PatientInit, PatientService } from '../../../services/patient/patient.service';

@Component({
  selector: 'pp-patient-profile',
  templateUrl: './patient-profile.component.html',
  styleUrls: ['./patient-profile.component.scss']
})
export class PatientProfileComponent implements OnInit {

  patient: Patient = PatientInit;

  constructor (
    private snackbar: MatSnackBar,
    private router: Router,
    private authService: AuthService,
    private patientService: PatientService
  ) { }

  ngOnInit() {
    this.patientService.getPatientWithPhoto().subscribe(res => this.patient = res, _ => this.signOutClick());
  }

  signOutClick() {
    this.authService.signOut();
  }

}