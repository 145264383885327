import { Component, OnInit, ViewEncapsulation, Inject, ViewChild, HostListener, ElementRef } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Doctor, DoctorInit, DoctorService } from '../../../services/doctor/doctor.service';

@Component({
  selector: 'pp-doctor-info',
  templateUrl: './doctor-info.component.html',
  styleUrls: ['./doctor-info.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DoctorInfoComponent implements OnInit {

  doctor: Doctor = DoctorInit;
  department: any = null;
  departments: any[] = [];
  scrollStyle: object = {};

  constructor(
    private dialog: MatDialog,
    private doctorService: DoctorService,
    private dialogRef: MatDialogRef<DoctorInfoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.doctorService.getDoctorById(this.data.doctorId).subscribe((res: Doctor) => {
      this.doctor = res;
    })
  }

}
