import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { ContactusComponent } from '../contactus/contactus.component';
import { SigninComponent } from '../signin/signin.component';
import { AuthService } from '../../../services/auth/auth.service';
import { Patient, PatientInit, PatientService } from '../../../services/patient/patient.service';
import { Client, ClientInit, ClientService } from '../../../services/client/client.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'pp-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  isAuthenticated: boolean;
  patient: Patient = PatientInit;
  client: Client = ClientInit;
  buildDisable: boolean = true;
  clientEnvironment = environment['client'] || "";

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private authService: AuthService,
    private patientService: PatientService,
    private clientService: ClientService
  ) {
    this.clientService.getClientDetails().subscribe(res => this.client = res.data || ClientInit);
  }

  ngOnInit() {
    this.isAuthenticated = this.authService.isAuthenticated();
    if (this.isAuthenticated) {
      this.authService.setOtherDetailsAfterLogin();
      this.patientService.getPatientWithPhoto().subscribe(res =>{this.patient = res;this.getChildPatientsPhoto();}, _ => this.signOutClick());
    }
  }


  getChildPatientsPhoto() {
    this.patient.nextofkin.forEach(e => {
      this.patientService.getPatientPhoto(e.id).subscribe(res => {
        if (res.patientimage && res.patientimage.patientphoto) {
          e.photo = res.patientimage && res.patientimage.patientphoto || null;
        }
      })
    });
  }

  homeBtnClick() {
    if (this.isAuthenticated) {
      this.router.navigateByUrl('');
    } else {
      this.router.navigateByUrl('home', { skipLocationChange: true });
    }
  }

  contactUs() {
    if (this.isAuthenticated) {
      const dialog = this.dialog.open(ContactusComponent, {
        panelClass: [`pp-contactus`],
        minWidth: 'calc(100vw - 100px)',
        minHeight: 'calc(100vh - 100px)',
        disableClose: true
      });
      dialog.afterClosed().subscribe(data => data && this.router.navigateByUrl(''));
    } else {
      this.router.navigateByUrl('home', { skipLocationChange: true });
    }
  }

  signInClick() {
    this.dialog.open(SigninComponent, {
      panelClass: 'pp-sign-in',
      width: '500px',
      disableClose: true
    });
  }

  signOutClick() {
    this.authService.signOut();
  }

  switchUser(patientid, externalid) {
    localStorage.setItem('token', patientid);
    localStorage.setItem('ref', externalid);
    setTimeout(() => {
      window.location.reload();
    }, 100);
  }

}