import { Component, ViewEncapsulation, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog, MatSnackBar } from '@angular/material';
import { SigninComponent } from '../../shared/signin/signin.component';
import { SignupComponent } from '../../shared/signup/signup.component';
import { AppointmentService } from '../../../services/appointment/appointment.service';
import { AuthService } from '../../../services/auth/auth.service';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { LoadingComponent } from '../loading/loading.component';
import { ReportViewComponent } from '../report-view/report-view.component';
import { PatientService } from '../../../services/patient/patient.service';
import { PaymentService } from '../../../services/payment/payment.service';

declare const Razorpay: any;

@Component({
  selector: 'pp-appointment-book',
  templateUrl: './appointment-book.component.html',
  styleUrls: ['./appointment-book.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppointmentBookComponent implements OnInit {

  isteleconsult = false;
  isAuthenticated: boolean;
  depositAmount: Number = 0;
  depositCurrency: string = null;
  depositCurrencyUid: string = null;
  depositEntypeUid: string = null;
  error: any = null;
  feeDetails: any = null;
  patientType = null;

  constructor (
    private dialog: MatDialog,
    private snackbar: MatSnackBar,
    private patientService: PatientService,
    private authService: AuthService,
    private appointmentService: AppointmentService,
    private paymentService: PaymentService,
    private translate: TranslateService,
    private dialogRef: MatDialogRef<AppointmentBookComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.isAuthenticated = this.authService.isAuthenticated();
    if (this.data.newData.slot.isonlinepaymentallowed) {
      this.getDepositAmount();
    }
    if (this.data.newData.slot.isportalteleconsultmandatory) {
      this.isteleconsult = true;
    }
  }

  closeRazorPayModal() {
    const razorpayContainer = document.getElementsByClassName('razorpay-container');
    if (razorpayContainer && razorpayContainer.length) {
      for (let i = 0; i < razorpayContainer.length; i++) {
        const style = razorpayContainer[i].getAttribute('style').replace('display: block', 'display: none');
        razorpayContainer[i].setAttribute('style', style);
      }
    }
  }

  showError() {
    this.authService.setOtherDetailsAfterLogin();
    if (this.error) {
      if (this.error && this.error._id && this.error.patientuid) {
        this.bookAppointment({
          reporttype: 'PAYMENTRECEIPT',
          searchcriteria: this.error._id,
          patientuid: this.error.patientuid
        });
      } else {
        this.snackbar.open(this.translate.instant(this.error), '', {
          panelClass: ['pp-snackbar-err'],
          verticalPosition: 'top',
          horizontalPosition: 'right',
          duration: 4000
        });
      }
    }    
  }

  paymentCheckout(data) {
    if (this.depositCurrency && this.depositAmount > 0) {
      this.authService.setIdelTimeout(null);
      const dialog = this.dialog.open(LoadingComponent, {
        minWidth: '100vw',
        minHeight: '100vh',
        maxWidth: '100vw',
        maxHeight: '100vh',
        panelClass: 'pp-data-loading',
        disableClose: true,
      });
      this.paymentService.createPayment({
        amount: +this.depositAmount * 100,
        currency: this.depositCurrency,
        patientuid: this.authService.getUser().refId,
        orguid: this.data.newData.slot.orguid
      }).subscribe(res => {
        const checkout = {
          ...res,
          modal: {
            escape: false,
            handleback: false
          }
        };
        if (data && data.patient) {
          checkout.prefill = {
            name: data.patient.firstname,
            email: data.patient.contact.emailid,
            contact: data.patient.contact.mobilephone
          }
        }
        if (checkout.order_id) {
          checkout.handler = (data) => {
            this.paymentService.verifyPayment({
              amount: this.depositAmount,
              currency: this.depositCurrency,
              currencyuid: this.depositCurrencyUid,
              entypeuid: this.depositEntypeUid,
              orderid: checkout.order_id,
              paymentid: data.razorpay_payment_id,
              signature: data.razorpay_signature,
              referenceuid: checkout.notes.referenceuid,
              patientuid: this.authService.getUser().refId,
              orguid: this.data.newData.slot.orguid,
              departmentuid: this.data.newData.slot.department._id,
              careprovideruid: this.data.newData.slot.careprovideruid
            }).subscribe(res => {
              this.closeRazorPayModal();
              this.error = res;
              document.getElementById('razorpay-btn').click();
            }, _ => {
              this.closeRazorPayModal();
              this.error = 'ERRORS.TRANSACTIONFAILED';
              document.getElementById('razorpay-btn').click();
            });
          };          
          const pay = new Razorpay(checkout);
          pay.on('payment.failed', () => {
            this.closeRazorPayModal();
            this.error = 'ERRORS.TRANSACTIONFAILED';
            document.getElementById('razorpay-btn').click();
          });
          pay.open();
        } else {
          this.authService.setOtherDetailsAfterLogin();
          this.snackbar.open(this.translate.instant('ERRORS.PAYMENTCREATEERROR'), '', {
            panelClass: ['pp-snackbar-err'],
            verticalPosition: 'top',
            horizontalPosition: 'right',
            duration: 4000
          });
        }
        setTimeout(() => dialog.close(), 200);
      }, _ => {
        setTimeout(() => {
          this.authService.setOtherDetailsAfterLogin();
          dialog.close();
          this.snackbar.open(this.translate.instant('ERRORS.PAYMENTCREATEERROR'), '', {
            panelClass: ['pp-snackbar-err'],
            verticalPosition: 'top',
            horizontalPosition: 'right',
            duration: 4000
          });
        }, 200);
      });
    } else {
      this.snackbar.open(this.translate.instant('ERRORS.REGFEECONFIGERROR'), '', {
        panelClass: ['pp-snackbar-err'],
        verticalPosition: 'top',
        horizontalPosition: 'right',
        duration: 4000
      });
    }
  }

  getDepositAmount() {
    const dialog = this.dialog.open(LoadingComponent, {
      minWidth: '100vw',
      minHeight: '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      panelClass: 'pp-data-loading',
      disableClose: true,
    });
    this.paymentService.getAppointmentFee({
      orguid: this.data.newData.slot.orguid,
      departmentuid: this.data.newData.slot.department._id,
      careprovideruid: this.data.newData.slot.careprovideruid
    }).subscribe(res => {
      this.feeDetails = res;
      this.depositAmount = this.isAuthenticated ? res.followupvisitamount : res.newvisitamount;
      this.patientType = this.isAuthenticated ? 'OLD' : null;
      this.depositCurrency = res.currency;
      this.depositCurrencyUid = res.currencyuid;
      this.depositEntypeUid = res.entypeuid;
      setTimeout(() => dialog.close(), 200);
    }, _ => {
      this.depositAmount = 0;
      this.depositCurrency = null;
      this.depositCurrencyUid = null;
      this.depositEntypeUid = null;
      setTimeout(() => dialog.close(), 200);
    });
  }

  signInClick() {
    this.depositAmount = this.feeDetails ? this.feeDetails.followupvisitamount : 0;

    if (!this.patientType && this.data.newData.slot.isonlinepaymentallowed && this.depositAmount > 0) {
      this.patientType = 'OLD';
      return;
    }

    const dialog = this.dialog.open(SigninComponent, {
      panelClass: 'pp-sign-in',
      width: '500px',
      disableClose: true,
      data: {
        mobile: null,
        orguid: this.data.newData.slot.orguid,
        scheduleuid: this.data.newData.slot.appointmentscheduleuid,
        start: this.data.newData.slot.starttime,
        end: this.data.newData.slot.endtime,
        isteleconsult: this.isteleconsult,
        dontNavigateToDashboard: true
      }
    });
    dialog.afterClosed().subscribe(success => success && this.bookApt());
  }

  signUpClick() {
    this.depositAmount = this.feeDetails ? this.feeDetails.newvisitamount : 0;
    
    if (!this.patientType && this.data.newData.slot.isonlinepaymentallowed && this.depositAmount > 0) {
      this.patientType = 'NEW';
      return;
    }
    
    const dialog = this.dialog.open(SignupComponent, {
      panelClass: 'pp-sign-up',
      width: '650px',
      disableClose: true,
      data: {
        mobile: null,
        orguid: this.data.newData.slot.orguid
      }
    });
    dialog.afterClosed().subscribe(patientId => patientId && this.bookApt());
  }

  bookApt() {
    this.patientService.getPatient().subscribe(res => {
      if (res && res.patient && res.patient.isblacklisted) {
        this.snackbar.open(this.translate.instant('ERRORS.BLACKLISTPATIENT'), '', {
          panelClass: ['pp-snackbar-err'],
          verticalPosition: 'top',
          horizontalPosition: 'right',
          duration: 4000
        });
        this.dialogRef.close(false);
        return;
      }

      if (this.data.newData.slot.isonlinepaymentallowed) {
        this.paymentCheckout(res);
      } else {
        this.bookAppointment(null);
      }
    });
  }

  bookAppointment(deposit) {
    const apt = {
      orguid: this.data.newData.slot.orguid,
      patientuid: this.authService.getUser().refId,
      scheduleuid: this.data.newData.slot.appointmentscheduleuid,
      start: this.data.newData.slot.starttime,
      end: this.data.newData.slot.endtime,
      isteleconsult: this.isteleconsult,
      deposituid: deposit ? deposit.searchcriteria : null
    }
    this.appointmentService.createAppointment(apt).subscribe(_ => {
      this.dialogRef.close(true);
      if (deposit && deposit.reporttype && deposit.searchcriteria && deposit.patientuid) {
        deposit.orguid = this.data.newData.slot.orguid;
        this.dialog.open(ReportViewComponent, {
          minWidth: 'calc(100vw - 100px)',
          minHeight: 'calc(100vh - 100px)',
          panelClass: 'pp-report-view',
          disableClose: true,
          data: deposit
        });
      }
    }, _ => this.dialogRef.close(false));
  }

}