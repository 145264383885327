import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AppointmentService {
  
  constructor (private http: HttpClient, private authService: AuthService) { }

  getPatientId = (): string => this.authService.getUser().refId;

  createAppointment(data): Observable<any> {
    return this.http.post<any>(`/api/patient/appointment/create`, data);
  }

  getUpcomingAppointment(): Observable<any> {
    return this.http.post<any>(`/api/patient/appointment/fetch`, { patientuid: this.getPatientId() });
  }

  editAppointment(data): Observable<any> {
    return this.http.post<any>(`/api/patient/appointment/edit`, data);
  }

  cancelAppointment(data): Observable<any> {
    return this.http.post<any>(`/api/patient/appointment/cancel`, data);
  }

  getCancelReasons(): Observable<any> {
    return this.http.post<any>(`/framework/referencedomain/getdropdownlist`, { domaincode: 'CANCELREASON' });
  }

}
