import { Component, ViewEncapsulation, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AppointmentService } from '../../../services/appointment/appointment.service';

@Component({
  selector: 'pp-appointment-cancel',
  templateUrl: './appointment-cancel.component.html',
  styleUrls: ['./appointment-cancel.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppointmentCancelComponent implements OnInit {

  aptCancelForm: FormGroup;
  cancelReasons: any[] = [];

  constructor (
    private fb: FormBuilder,
    private appointmentService: AppointmentService,
    private snackbar: MatSnackBar,
    private dialogRef: MatDialogRef<AppointmentCancelComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.aptCancelForm = this.fb.group({
      reason: [null, Validators.required],
      comment: [null]
    });
    this.appointmentService.getCancelReasons().subscribe(res => this.cancelReasons = res.dropdownlist);
  }

  confirmClick() {
    const apt = {
      _id: this.data._id,      
      orguid: this.data.org._id,
      scheduleuid: this.data.scheduleuid,
      statusuid: this.data.statusuid._id,
      reasonuid: this.aptCancelForm.value.reason,
      comments: this.aptCancelForm.value.comment
    }
    this.appointmentService.cancelAppointment(apt).subscribe(res => {
      if (res.code === 400) {
        this.snackbar.open('Appointment has been confirmed', '', {
          panelClass: ['pp-snackbar-err'],
          verticalPosition: 'top',
          horizontalPosition: 'right',
          duration: 5000
        });
      } else {
        this.snackbar.open('Appointment Cancelled Successfully', '', {
          panelClass: ['pp-snackbar-ok'],
          verticalPosition: 'top',
          horizontalPosition: 'right',
          duration: 5000
        });
      }
      this.dialogRef.close(true);
    }, _ => {
      this.snackbar.open('Appointment Cancel Failed', '', {
        panelClass: ['pp-snackbar-err'],
        verticalPosition: 'top',
        horizontalPosition: 'right',
        duration: 5000
      });
      this.dialogRef.close();
    });
  }

}