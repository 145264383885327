import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FileUploadService } from 'src/app/services/file-upload/file-upload.service';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'pp-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {

  files: any = [];
  patientuid = '';
  fileFormat = ['.pdf', '.doc', '.docx', '.jpeg', '.png']
  validFileError: boolean = false;
  selectedFile: any;
  fileUploadLength: number = 0;
  orguid: string;

  constructor(public dialogRef: MatDialogRef<FileUploadComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public uploadService: FileUploadService,private authService: AuthService) { }

  ngOnInit() {
  }


  uploadFile(event) {
    for (let index = 0; index < event.length; index++) {
      let uploadFileFormat = '.' + event[index].name.split(".").pop();
      if (this.fileFormat.includes(uploadFileFormat) === false || event.length > 1 ) {
        this.validFileError = true;
      }
      else {
        this.validFileError = false;
        const element = event[index];
        if (this.files.length + 1 > 1 || event[index].size > 5242880) {
          this.validFileError = true;
        } else {
          this.fileUploadLength = event.length;
          this.files.push(element.name);
          this.selectedFile = event; 
        }
      }

    }
  }
  deleteAttachment(index) {
    this.files.splice(index, 1)
    this.fileUploadLength = this.fileUploadLength - 1
  }

  uploadSelectedFile(){
    this.patientuid = this.authService.getUser().refId;
    this.orguid =this.data.orguid;
    this.uploadService.postFile(this.selectedFile,this.patientuid,this.orguid).subscribe(res =>{
      this.dialogRef.close('success');
    })
  }

}
