import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { Client, ClientInit, ClientService } from '../client/client.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor (
    private router: Router,
    private authService: AuthService,
    private clientService: ClientService
  ) { }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const isAuthenticated: boolean = this.authService.isAuthenticated();
    if (route.data.auth) {
      if (!isAuthenticated) {
        this.clientService.getClientDetails().subscribe(res => {
          const client: Client = res.data || ClientInit;
          if (client.name === 'mgm') {
            this.router.navigateByUrl('appointment', { skipLocationChange: true });
          } else {
            this.router.navigateByUrl('home', { skipLocationChange: true });
          }
        }, _ => {
          this.router.navigateByUrl('home', { skipLocationChange: true });
        });
        return false;
      }
      return true;
    } else {
      if (isAuthenticated) {
        this.router.navigateByUrl('');
        return false;
      }
      return true;
    }
  }
}
