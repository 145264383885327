import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'pp-idle-timeout',
  templateUrl: './idle-timeout.component.html',
  styleUrls: ['./idle-timeout.component.scss']
})
export class IdleTimeoutComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<IdleTimeoutComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  timeLeft: number = 30;
  interval;

  startTimer() {
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.timeLeft = 0;
      }
    }, 1000)
  }

  ngOnInit() {
    this.startTimer();
  }

  signOut() {
    this.dialogRef.close(true);
  }

  stopTimer() {
    this.dialogRef.close();
  }


}
