import { Component, ViewEncapsulation, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppointmentService } from '../../../services/appointment/appointment.service';
import { AuthService } from '../../../services/auth/auth.service';

@Component({
  selector: 'pp-appointment-reschedule',
  templateUrl: './appointment-reschedule.component.html',
  styleUrls: ['./appointment-reschedule.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppointmentRescheduleComponent implements OnInit {

  aptRescheduleForm: FormGroup;
  rescheduleReasons: any[] = [];

  constructor (
    private fb: FormBuilder,
    private snackbar: MatSnackBar,
    private appointmentService: AppointmentService,
    private authService: AuthService,
    private dialogRef: MatDialogRef<AppointmentRescheduleComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.aptRescheduleForm = this.fb.group({
      reason: [null, Validators.required],
      comment: [null]
    });
    this.appointmentService.getCancelReasons().subscribe(res => this.rescheduleReasons = res.dropdownlist);
  }

  rescheduleApt() {
    const apt = {
      _id: this.data.oldData._id, 
      patientuid: this.authService.getUser().refId,
      orguid: this.data.newData.slot.orguid,           
      oldscheduleuid: this.data.oldData.scheduleuid,
      scheduleuid: this.data.newData.slot.appointmentscheduleuid,
      statusuid: this.data.oldData.statusuid._id,      
      reasonuid: this.aptRescheduleForm.value.reason,
      comments: this.aptRescheduleForm.value.comment,    
      start: this.data.newData.slot.starttime,
      end: this.data.newData.slot.endtime
    }
    this.appointmentService.editAppointment(apt).subscribe(res => {
      if (res.code === 400) {
        this.snackbar.open('Appointment has been confirmed', '', {
          panelClass: ['pp-snackbar-err'],
          verticalPosition: 'top',
          horizontalPosition: 'right',
          duration: 5000
        });
      } else {
        this.snackbar.open('Appointment Rescheduled Successfully', '', {
          panelClass: ['pp-snackbar-ok'],
          verticalPosition: 'top',
          horizontalPosition: 'right',
          duration: 5000
        });
      }
      this.dialogRef.close(true);
    }, _ => {
      this.snackbar.open('Appointment Reschedule Failed', '', {
        panelClass: ['pp-snackbar-err'],
        verticalPosition: 'top',
        horizontalPosition: 'right',
        duration: 5000
      });
      this.dialogRef.close();
    });
  }

}