import { Component, ViewEncapsulation, Inject, ViewChild, ElementRef, HostListener, OnInit, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AppointmentBookComponent } from '../appointment-book/appointment-book.component';
import { AppointmentRescheduleComponent } from '../appointment-reschedule/appointment-reschedule.component';
import { Doctor, DoctorInit, DoctorService } from '../../../services/doctor/doctor.service';
import { Moment } from 'moment';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';

const today: Moment = moment.utc().startOf('day');

const dateValid = (date): boolean => moment.utc(date).isSameOrBefore(today);

@Component({
  selector: 'pp-doctor-time-slot',
  templateUrl: './doctor-time-slot.component.html',
  styleUrls: ['./doctor-time-slot.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DoctorTimeSlotComponent implements OnInit, AfterViewInit  {

  @ViewChild('fixed', { static: false }) fixed: ElementRef;
  @HostListener('window:resize') onResize = () => this.setScrollStyle();
  viewInit: boolean = false;
  loading: boolean = true;
  scrollStyle: object = {};
  doctor: Doctor = DoctorInit;
  timeSlot: any[] = [];
  department: any = null;
  departments: any[] = [];
  _timeSlot: any[] = [];
  formGroup: FormGroup;
  minDate: Moment = today;
  prevDisable: boolean = false;
  teleconsultavailable :string;

  constructor (
    private fb: FormBuilder,
    private dialog: MatDialog,
    private doctorService: DoctorService,
    private dialogRef: MatDialogRef<DoctorTimeSlotComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.teleconsultavailable = this.translate.instant('TELECONSULTAVAILABLE');
    this.formGroup = this.fb.group({ date: [today]});
    this.doctorService.getDoctorById(this.data.doctorId).subscribe((res: Doctor) => {
      this.doctor = res;
      this.loadTimeSlot();
    }, _ => this.hideLoader());
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.setScrollStyle();
      this.viewInit = true;
    }, 200);
  }

  loadTimeSlot() {
    this.getTimeSlots(this.formGroup.value.date);
  }

  loadPrevTimeSlot() {
    this.getTimeSlots(moment.utc(this.formGroup.value.date).subtract(7, 'day'));
  }

  loadNextTimeSlot() {
    this.getTimeSlots(moment.utc(this.formGroup.value.date).add(7, 'day'));
  }

  getTimeSlots(from) {
    this.loading = true;
    this.prevDisable = dateValid(from);
    this.prevDisable && (from = today);
    this.formGroup.setValue({date: from});
    const data = {
      orguid: this.data.currentOrguid,
      careprovideruid: this.doctor.externalid,
      fromdate: from,
      todate: moment.utc(from).add(7, 'day'),
    }
    this.doctorService.getDoctorTimeSlot(data).subscribe(res => {
      this._timeSlot = res.freeSlots;
      if (this._timeSlot && this._timeSlot.length) {
        this.departments = this._timeSlot.map(e => e.department).filter((prev, index, next) => next.map(e => e['_id']).indexOf(prev['_id']) === index);
        this.departments.sort((a, b) => a.name.localeCompare(b.name));
        if(!this.department)
        this.department = this.departments[0]['_id'];
        else
        this.department = this.department;
        
        this.groupingTimeSlot();
      } else {
        this.timeSlot = [];
        this.departments = [];
        this.department = null;
      }
      this.hideLoader();
    }, _ => this.hideLoader());
  }

  timeSlotClick(data) {
    data.dept = this.departments.find(e => e._id === this.department).name;
    let dialog;
    if (this.data.appointment) {
      dialog = this.dialog.open(AppointmentRescheduleComponent, {
        width: '600px',
        panelClass: 'pp-apt-reschedule',
        disableClose: true,
        data: {
          oldData: this.data.appointment,
          newData: data
        }
      });
    } else {
      dialog = this.dialog.open(AppointmentBookComponent, {
        width: '500px',
        panelClass: 'pp-apt-book',
        disableClose: true,
        data: {
          oldData: null,
          newData: data
        }
      });
    }
    dialog.afterClosed().subscribe(data => data && this.dialogRef.close(data));
  }

  groupingTimeSlot() {
    if (this.department && this._timeSlot && this._timeSlot.length) {
      const data = this._timeSlot.filter(e => e.department._id === this.department);
      const groups = data.reduce((groups, slot) => {
        const date =  moment(slot.starttime).format("YYYY-MM-DD");
        if (!groups[date]) {
          groups[date] = [];
        }
        groups[date].push(slot);
        return groups;
      }, {});
      this.timeSlot = Object.keys(groups).map((date: any) => date = { date, slots: groups[date] });
    }
  }

  setScrollStyle() {
    this.scrollStyle = {
      'max-height': `calc(100vh - 40px - ${this.fixed.nativeElement.offsetHeight + 4}px)`,
      'min-height': `calc(100vh - 40px - ${this.fixed.nativeElement.offsetHeight + 4}px)`,
      'height': `calc(100vh - 40px - ${this.fixed.nativeElement.offsetHeight + 4}px)`
    };
  }

  hideLoader() {
    setTimeout(() => this.loading = false, 1000);
  }

}