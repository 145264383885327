import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(private http: HttpClient) { }

  getAppointmentFee(data): Observable<any> {
    return this.http.post<any>(`/api/appointment/fee`, data);
  }

  createPayment(data): Observable<any> {
    return this.http.post<any>(`/api/payment/create`, data);
  }

  verifyPayment(data): Observable<any> {
    return this.http.post<any>(`/api/payment/verify`, data);
  }

  viewReport(data): Observable<any> {
    return this.http.post<any>(`/api/report/view`, data);
  }
}
