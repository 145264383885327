import { NgModule } from '@angular/core';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import {
  MatInputModule, MatDatepickerModule, MatSelectModule, MatButtonModule,
  MatFormFieldModule, MatMenuModule, MatTabsModule, MatTableModule,
  MatDialogModule, MatNativeDateModule, MatSnackBarModule, MatRippleModule,
  MatTooltipModule, DateAdapter, MAT_DATE_FORMATS
} from '@angular/material';

const DATE_FORMAT = {
  parse: {
    dateInput: 'DD-MM-YYYY',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'DD-MM-YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  }
};

@NgModule({
  exports: [
    MatInputModule, MatDatepickerModule, MatSelectModule, MatButtonModule,
    MatFormFieldModule, MatMenuModule, MatTabsModule, MatTableModule,
    MatDialogModule, MatNativeDateModule, MatSnackBarModule, MatRippleModule,
    MatTooltipModule
  ],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMAT },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }
  ]
})
export class MaterialModule { }