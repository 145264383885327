import { Component, ViewEncapsulation, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { ReportService } from '../../../services/report/report.service';

@Component({
  selector: 'pp-documents-view',
  templateUrl: './documents-view.component.html',
  styleUrls: ['./documents-view.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DocumentsViewComponent implements OnInit {

  documents = [];
  loading = true;

  constructor(private sanitizer: DomSanitizer, private reportService: ReportService, @Inject(MAT_DIALOG_DATA) public data: any) { }

  base64ToArrayBuffer(base64) {
    const binary = window.atob(base64);
    const bytes = new Uint8Array(binary.length);
    for (let i = 0; i < binary.length; i++) bytes[i] = binary.charCodeAt(i);
    return bytes;
  };

  binaryToArrayBuffer(binary) {
    const bytes = new Uint8Array(binary.length);
    for (let i = 0; i < binary.length; i++) bytes[i] = binary[i];
    return bytes;
  }

  ngOnInit() {
    if (this.data.isAttachment) {
      this.reportService.getLabDocuments({ labresultuid: this.data.labresultuid }).subscribe(res => {
        this.documents = (res || []).filter(e => e && e.document);
        this.documents.forEach(e => {
          const dataArray = e.document.split(';base64,');
          const base64String = dataArray[1];
          const contentType = dataArray[0].split('data:')[1];
          if (base64String && contentType) {
            const blob = new Blob([this.base64ToArrayBuffer(base64String)], { type: contentType });
            e.file = URL.createObjectURL(blob);
            e.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(e.file);
          }
        });
        this.loading = false;
      });
    } else if (this.data.isForm) {
      this.reportService.getFormDocuments({ formuid: this.data.formuid, orguid: this.data.orguid }).subscribe(res => {
        if (res.data) {
          const blob = new Blob([this.binaryToArrayBuffer(res.data)], { type: res.type });
          const file = URL.createObjectURL(blob);
          this.documents.push({
            documentname: res.name,
            documenttype: res.type,
            file: file,
            fileUrl: this.sanitizer.bypassSecurityTrustResourceUrl(file)
          });
        }
        this.loading = false;
      });
    } else if (this.data.isCert) {
      this.reportService.getCertDocuments({ certuid: this.data.certuid, orguid: this.data.orguid }).subscribe(res => {
        if (res.data) {
          const blob = new Blob([this.binaryToArrayBuffer(res.data)], { type: res.type });
          const file = URL.createObjectURL(blob);
          this.documents.push({
            documentname: res.name,
            documenttype: res.type,
            file: file,
            fileUrl: this.sanitizer.bypassSecurityTrustResourceUrl(file)
          });
        }
        this.loading = false;
      });
    }
  }

  newTabClick() {
    this.documents.forEach(e => window.open(e.file));
  }

  downloadClick() {
    const a = document.createElement('a');
    this.documents.forEach(e => {
      a.href = e.file;
      a.download = e.documentname;
      a.click();
    });
  }

}
