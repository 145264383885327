import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import * as moment from 'moment';

export interface Patient {
  id:string;
  externalid:string;
  name: string;
  mrn: string;
  gender: string;
  dob: string;
  year:string;
  loginid: string;
  mobile: string;
  email?: string;
  address?: string;
  photo?: string;
  nextofkin?: [Patient];
  orguid?:string;
  
}

export const PatientInit: Patient = {
  id:null,
  externalid:null,
  name: 'null',
  mrn: null,
  gender: null,
  dob: null,
  year:null,
  mobile: null,
  loginid: null,
  email: null,
  address: null,
  photo:null,
  orguid:null
}

export interface PatientVisit {
  [key: string]: any;
}

export interface PatientReport {
  [key: string]: any;
}


export enum ReportType {
  BILL = 'BILL',
  RADIOLOGY = 'RADIOLOGY',
  LAB = 'LAB'
}

const getAge = (dob, isonlyyear?): string => {
  if (dob) {
    dob = moment(dob);
    const age = moment.duration(moment().diff(dob));
    if (isonlyyear) {
       return `${age.years()} Y`;
    }
    else {
      return `${dob.format('DD-MM-YYYY')} ${age.years()} Y ${age.months()} M ${age.days()} D`;
    }
  }
  return '';
}


const getPatient = (data): Patient => {
  let name = data.firstname || '';
  data.middlename && (name += ' ' + data.middlename);
  data.lastname && (name += ' ' + data.lastname);
  let nextofkin = data.childpatients && data.childpatients || [];
  nextofkin = nextofkin.map(e => getPatient(e));

  return {
    name: name,
    id:data._id || null,
    externalid:data.externalid ||  null,
    mrn: data.mrn || null,
    gender: data.genderuid && data.genderuid.valuedescription || null,
    dob: getAge(data.dateofbirth),
    year:getAge(data.dateofbirth,true),
    loginid: data.loginid && data.loginid || null,
    mobile: data.contact && data.contact.mobilephone || null,
    email: data.contact && data.contact.emailid || null,
    photo: data.photo && data.photo.patientphoto || null,
     nextofkin:nextofkin,
     orguid:data.orguid
  };
}


@Injectable({
  providedIn: 'root'
})
export class PatientService {
  
  constructor (private http: HttpClient, private authService: AuthService) { }

  getPatientId = (): string => this.authService.getUser().patientId;

  getPatient(): Observable<any> {
    return this.http.post<any>(`/mpi/patient/getbasicdetail`, { id: this.getPatientId() });
  }

  getPatientPhoto(patientid: string): Observable<any> {
    return this.http.post<any>(`/mpi/patient/getpatientphoto`, { id: patientid });
  }

  getPatientWithPhoto(): Observable<Patient> {
    return forkJoin(this.getPatient(), this.getPatientPhoto(this.getPatientId())).pipe(map(res => getPatient({ ...res[0].patient, photo: res[1].patientimage})));
  }

  getLatestVisit(): Observable<any> {
    return this.http.post<any>(`/mpi/patient/visit/getlatest`, { id: this.getPatientId() });
  }

  getAllVisits(): Observable<any> {
    return this.http.post<any>(`/mpi/patient/visit/getall`, { id: this.getPatientId() });
  }

  getPatientReports(visitId: string, type?: ReportType): Observable<any> {
    let body: any = { id: visitId };
    type && (body.type = type);
    const orguid = localStorage.getItem('orguid') || null;
    if(orguid) body.orguid = orguid;
    return this.http.post<any>(`/mpi/patientvisit/getreportsforpatientvisit`, body);
  }

  getPatientReportById(reportId: string): Observable<any> {
    return this.http.post<any>(`/mpi/patientvisit/getreportdetail`, { id: reportId });
  }

  getPatientAllergy(): Observable<any> {
    return this.http.get<any>(`/api/patient/allergy/${this.getPatientId()}`);
  }

}
