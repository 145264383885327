import { Component } from '@angular/core';
import * as tinycolor from 'tinycolor2';
import { Client, ClientInit, ClientService } from './services/client/client.service';

interface Color {
  name: string;
  hex: string;
  darkContrast: boolean;
};

const getColorObject = (value, name): Color => {
  const color = tinycolor(value);
  return {
    name: name,
    hex: color.toHexString(),
    darkContrast: color.isLight()
  };
};

const computeColors = (hex: string): Color[] => {
  return [
    getColorObject(tinycolor(hex).lighten(52), '50'),
    getColorObject(tinycolor(hex).lighten(37), '100'),
    getColorObject(tinycolor(hex).lighten(26), '200'),
    getColorObject(tinycolor(hex).lighten(12), '300'),
    getColorObject(tinycolor(hex).lighten(6), '400'),
    getColorObject(tinycolor(hex), '500'),
    getColorObject(tinycolor(hex).darken(6), '600'),
    getColorObject(tinycolor(hex).darken(12), '700'),
    getColorObject(tinycolor(hex).darken(18), '800'),
    getColorObject(tinycolor(hex).darken(24), '900'),
    getColorObject(tinycolor(hex).lighten(50).saturate(30), 'A100'),
    getColorObject(tinycolor(hex).lighten(30).saturate(30), 'A200'),
    getColorObject(tinycolor(hex).lighten(10).saturate(15), 'A400'),
    getColorObject(tinycolor(hex).lighten(5).saturate(5), 'A700')
  ];
};

const setTheme = (data: Client): void => {
  computeColors(data.theme.primary).forEach(color => {
    document.documentElement.style.setProperty(`--primary-${color.name}`, color.hex);
    document.documentElement.style.setProperty(`--primary-contrast-${color.name}`, color.darkContrast ? '0' : '1');
  });
};

@Component({
  selector: 'pp-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(private clientService: ClientService) {
    this.clientService.getClientDetails().subscribe(res => {
      setTheme(res.data || ClientInit);
    }, _ => {
      setTheme(ClientInit);
    });
  } 
}