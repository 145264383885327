import { Component, ViewEncapsulation, Inject, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { FormBuilder } from '@angular/forms';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'pp-teleconsult',
  templateUrl: './teleconsult.component.html',
  styleUrls: ['./teleconsult.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TeleconsultComponent implements AfterViewInit {

  @ViewChild("videoCallCont", null) videoCallContEle: ElementRef;
  constructor (
    private fb: FormBuilder,
    private snackbar: MatSnackBar,
    private dialogRef: MatDialogRef<TeleconsultComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngAfterViewInit() {
    if (!this.data || !this.data.patient || !this.data.patient.mrn || !this.videoCallContEle || !this.videoCallContEle.nativeElement) return;
    console.log(this.videoCallContEle);
    console.log(this.videoCallContEle.nativeElement);
    let url = "";
    if (environment.apiUrl) url = environment.apiUrl + "/";
    let width = this.videoCallContEle.nativeElement.offsetWidth;
    let height = this.videoCallContEle.nativeElement.offsetHeight;
    if (!width || width < 600) width = 600;
    if (!height || height < 300) height = 300;
    const videoWidth = `${width - 20}px`;
    const videoHeight = `${height - 20}px`;
    url = `${url}api/videocall/doVideoCall?roomid=${this.data.patient.mrn}&width=${videoWidth}&height=${videoHeight}`;
    this.videoCallContEle.nativeElement.innerHTML = `<iframe *ngIf="data.patient.mrn" src="${url}" allow="microphone; camera" style="width: 100%; height: 100%; border: none;" title="Video Call"></iframe>`;
  }

}