import { Component, ViewEncapsulation, ViewChild, ElementRef, HostListener, OnInit, AfterViewInit, TemplateRef } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { MatDialog } from '@angular/material';
import { DoctorTimeSlotComponent } from '../shared/doctor-time-slot/doctor-time-slot.component';
import { AppointmentCancelComponent } from '../shared/appointment-cancel/appointment-cancel.component';
import { PatientService, ReportType, Patient, PatientInit } from '../../services/patient/patient.service';
import { AppointmentService } from '../../services/appointment/appointment.service';
import { Client, ClientInit, ClientService } from '../../services/client/client.service';
import { TeleconsultComponent } from '../shared/teleconsult/teleconsult.component';
import { FileUploadComponent } from '../shared/file-upload/file-upload.component';
import { AuthService } from 'src/app/services/auth/auth.service';
import { FileUploadService } from 'src/app/services/file-upload/file-upload.service';
import { SettingsService } from '../../services/settings/settings.service';
import * as FileSaver from 'file-saver';
import { DomSanitizer } from '@angular/platform-browser';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { ReportViewComponent } from '../shared/report-view/report-view.component';


@Component({
  selector: 'pp-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DashboardComponent implements OnInit, AfterViewInit {

  @ViewChild('page', { static: false }) page: ElementRef;
  @HostListener('window:resize') onResize = () => this.setScrollStyle();
  clientEnvironment = environment['client'] || "";
  viewInit: boolean = false;
  loading: boolean = true;
  scrollStyle: object = {};
  appointments: any[] = [];
  allAppointments: any[] = [];
  currentAppointments: any[] = [];
  visits: any[] = [];
  allergiesColumns: string[] = ['name', 'type', 'severity', 'comments'];
  allergies: any[] = [];
  bills: any[] = [];
  radiologyReports: any[] = [];
  labResults: any[] = [];
  client: Client = ClientInit;
  patient: Patient = PatientInit;
  patientuid: any;
  uploadedDocuments: any[] = [];
  doc: string;
  file: any;
  fileUrl: any;
  filetype: any;
  hasUploadRefvalue : boolean = false;
  setting: any = {};

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private patientService: PatientService,
    private appointmentService: AppointmentService,
    private clientService: ClientService,
    private settingService: SettingsService,
    public uploadService: FileUploadService, private authService: AuthService,
    private sanitizer: DomSanitizer
  ) {
    this.router.events.forEach((event) => {
      if(event instanceof NavigationStart) {
        if (event.url === '/dashboard') {
          this.loadUpcomingAppointment();
        }
      }
    });
    this.clientService.getClientDetails().subscribe(res => this.client = res.data || ClientInit);
    this.settingService.getPortalSetting().subscribe(res => this.setting = res || {});
  }

  ngOnInit() {
    this.loadUpcomingAppointment();
    this.loadLatestVisits();
    this.loadAllergies();
    this.getPatientDetails();
    this.getUploadRef();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.setScrollStyle();
      this.viewInit = true;
    }, 200);
  }

  tabClick(tab) {
    switch (tab.tab.textLabel) {
      case 'HOME':
        this.ngOnInit();
        break;
      case 'VISIT':
        this.loadVisits();
        break;
      case 'BILL':
        this.loadBills();
        break;
      case 'RADIOLOGY':
        this.loadRadiologyReports();
        break;
      case 'LAB':
        this.loadLabResults();
        break;
      case 'Upload Documents':
        this.loadDocuments();
        break;
      default:
        break;
    }
  }

  loadUpcomingAppointment() {
    this.loading = true;
    this.appointmentService.getUpcomingAppointment().subscribe(res => {
      this.allAppointments = res.appointments || [];
      this.setAppointmentData();
      this.hideLoader();
    }, _ => this.hideLoader());
  }

  viewReceipt(data) {
    this.dialog.open(ReportViewComponent, {
      minWidth: 'calc(100vw - 100px)',
      minHeight: 'calc(100vh - 100px)',
      panelClass: 'pp-report-view',
      disableClose: true,
      data: {
        reporttype: 'PAYMENTRECEIPT',
        searchcriteria: data.deposituid,
        patientuid: data.patientuid ? (data.patientuid._id || data.patientuid) : null
      }
    });
  }

  setAppointmentData() {
    this.currentAppointments = [];
    this.appointments = [];
    if (!this.allAppointments || !this.allAppointments.length) return;

    this.allAppointments.forEach(appt => {
      const currentTime = new Date();
      currentTime.setSeconds(0, 0);
      const start = appt.start ? moment(appt.start).add(-5, 'minute').toDate() : null;
      if (start && appt.end && currentTime >= start && currentTime <= new Date(appt.end)) {
        this.currentAppointments.push(appt);
      } else {
        this.appointments.push(appt);
      }
    });
    /** refresh this function for every 1 min */
    setTimeout(() => {
      this.setAppointmentData();
    }, 1000 * 60);
  }

  loadLatestVisits() {
    this.patientService.getLatestVisit().subscribe(res => {
      this.visits = res.data ? [res.data] : [];
      this.visits.forEach(e => this.patientService.getPatientReports(e._id).subscribe(res => e.reports = res.data || []));
    });
  }

  loadAllergies() {
    this.patientService.getPatientAllergy().subscribe(res => this.allergies = res.data || []);
  }

  loadVisits() {
    this.loading = true;
    this.patientService.getAllVisits().subscribe(res => {
      this.visits = res.data || [];
      this.visits.forEach(e => {
        this.patientService.getPatientReports(e._id).subscribe(res => {
          e.reports = res.data || [];
          this.hideLoader();
        }, _ => this.hideLoader());
      });
      this.hideLoader()
    }, _ => this.hideLoader());
  }

  loadBills() {
    this.loading = true;
    this.bills = [];
    this.patientService.getAllVisits().subscribe(res => {
      (res.data || []).forEach(e => {
        this.patientService.getPatientReports(e._id, ReportType.BILL).subscribe(res => {
          e.reports = res.data || [];
          e.reports.length && this.bills.push(e);
          this.bills.sort((a, b) => new Date(b.startdate).getTime() - new Date(a.startdate).getTime());
          this.hideLoader();
        }, _ => this.hideLoader());
      });
      this.hideLoader();
    }, _ => this.hideLoader());
  }

  loadRadiologyReports() {
    this.loading = true;
    this.radiologyReports = [];
    this.patientService.getAllVisits().subscribe(res => {
      (res.data || []).forEach(e => {
        this.patientService.getPatientReports(e._id, ReportType.RADIOLOGY).subscribe(res => {
          e.reports = res.data || [];
          e.reports.length && this.radiologyReports.push(e);
          this.hideLoader();
        }, _ => this.hideLoader());
      });
      this.hideLoader();
    }, _ => this.hideLoader());
  }

  loadLabResults() {
    this.loading = true;
    this.labResults = [];
    this.patientService.getAllVisits().subscribe(res => {
      (res.data || []).forEach(e => {
        this.patientService.getPatientReports(e._id, ReportType.LAB).subscribe(res => {
          e.reports = res.data || [];
          e.reports.length && this.labResults.push(e);
          this.hideLoader();
        }, _ => this.hideLoader());
      });
      this.hideLoader();
    }, _ => this.hideLoader());
  }

  getCareProviderName(data: any[]): string {
    if (!data || !data.length) return '';
    data = (data || []).map(e => {
      let string = '';
      const title = e.careprovideruid && e.careprovideruid.titleuid && e.careprovideruid.titleuid.valuedescription && e.careprovideruid.titleuid.valuedescription || '';
      const name = e.careprovideruid && e.careprovideruid.name && e.careprovideruid.name || '';
      const dept = e.department || '';
      title && (string += `${title} `);
      name && (string += name);
      dept && (string += `, ${dept}`);
      return string.trim();
    }).filter(e => e !== '');
    return data && data.length ? data.join(' | ') : '';
  }

  bookAppointment() {
    this.router.navigateByUrl('appointment', { skipLocationChange: true });
  }

  rescheduleAppointment(data) {
    const dialog = this.dialog.open(DoctorTimeSlotComponent, {
      minWidth: 'calc(100vw - 40px)',
      minHeight: 'calc(100vh - 40px)',
      maxWidth: 'calc(100vw - 40px)',
      maxHeight: 'calc(100vh - 40px)',
      panelClass: 'pp-doctor-time-slot',
      disableClose: true,
      data: { doctorId: data.careprovideruid._id || null, appointment: data, currentOrguid: (data.org && data.org._id) ? data.org._id : this.patient.orguid }
    });
    dialog.afterClosed().subscribe(data => data && this.loadUpcomingAppointment());
  }

  cancelAppointment(data) {
    const dialog = this.dialog.open(AppointmentCancelComponent, {
      panelClass: 'pp-apt-cancel',
      width: '500px',
      disableClose: true,
      data: data
    });
    dialog.afterClosed().subscribe(data => data && this.loadUpcomingAppointment());
  }

  startTeleConsult(appointment) {
    this.authService.setIdelTimeout(null);
    const dialog = this.dialog.open(TeleconsultComponent, {
      panelClass: 'pp-apt-cancel',
      width: '70%',
      height: '85%',
      disableClose: true,
      data: {
        patient: this.patient,
        appointment,
      }
    });
    dialog.afterClosed().subscribe(data =>{ 
      if(data){
        this.authService.setOtherDetailsAfterLogin();
        this.loadUpcomingAppointment();
      }
    });
  }

  getPatientDetails() {
    this.patientService.getPatientWithPhoto().subscribe(res => this.patient = res);
  }

  setScrollStyle() {
    this.scrollStyle = {
      'height': `calc(${this.page.nativeElement.offsetHeight}px - ${50}px)`
    };
  }

  hideLoader() {
    setTimeout(() => this.loading = false, 1000);
  }

  uploadDocument() {
    let dialogRef = this.dialog.open(FileUploadComponent, {
      width: '700px',
      disableClose: true,
      hasBackdrop: true,
      data:{orguid:this.patient.orguid}
    });
    dialogRef.afterClosed().subscribe(data => {
      if (data === 'success') {
        this.loadDocuments();
      }

    });
  }

  loadDocuments() {
    this.loading = true;
    this.patientuid = this.authService.getUser().refId;
    let requestpayload = {
      "patientuid": this.patientuid
    }
    this.uploadService.getdocuments(requestpayload).subscribe(res => {
      if(res.scanneddocument){
        this.uploadedDocuments = res.scanneddocument;
        this.hideLoader();
      }
    })
  }

  deleteDocument(confirmDeleteDialogRef : TemplateRef<any>,document) {
    const dialog = this.dialog.open(confirmDeleteDialogRef, {
      disableClose: true,
    });

    dialog.afterClosed().subscribe(res => {
      if(res === true){
        let payload = {
          "document": document._id
        }
        this.uploadService.deleteDocument(payload).subscribe(res => {
          this.loadDocuments();
        })
      }
    });
  }


  downloadDocument(document) {
    this.uploadService.getDocument(document._id).subscribe(res => {

      const data = res;
      if (data.scanneddocument != null && data.scanneddocument.scanneddocument != null) {
        const file = data.scanneddocument;
        const blob = new Blob([this.toArrayBuffer(file.scanneddocument.data)], {
          type: file.filetype
        });
        // saveAs provided by FileSaver.js
        FileSaver.saveAs(blob, file.documentname);
      } else {
        // vm.error = 'COMMON.NO_RECORD_FOUND';
      }
    })
  }

  toArrayBuffer(buf) {
    const ab = new ArrayBuffer(buf.length);
    const view = new Uint8Array(ab);
    for (let i = 0; i < buf.length; ++i) {
      view[i] = buf[i];
    }
    return ab;
  }

  viewDocumentDialog(viewDialogRef: TemplateRef<any>, document) {
    this.uploadService.getDocument(document._id).subscribe(res => {

      const data = res;
      if (data.scanneddocument != null && data.scanneddocument.scanneddocument != null) {
        const file = data.scanneddocument;        
        this.filetype = file.documentname.split(".").pop()
        this.file = new Blob([this.toArrayBuffer(file.scanneddocument.data)], {
          type: file.filetype
        });
        this.file = URL.createObjectURL(this.file);
        this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.file);
        const dialog = this.dialog.open(viewDialogRef, {
          minWidth: 'calc(100vw - 40px)',
          disableClose: true,
          data: { document: document._id }
        });
      } else {
        // vm.error = 'COMMON.NO_RECORD_FOUND';
      }
    })
  }

  getUploadRef(){
    this.uploadService.getUploadRef().subscribe(res =>{
      if (res && res.uploadRefValue && res.uploadRefValue[0] && res.uploadRefValue[0].valuecode === 'PD' && res.uploadRefValue[0].domaincode === 'PDOCTY') {
        this.hasUploadRefvalue=true;
      }
    });
  }

}