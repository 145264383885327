import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MatButtonModule, MatCheckboxModule, MatFormFieldModule, MatInputModule, MatRippleModule, MatProgressSpinnerModule, MatDatepickerModule, MatNativeDateModule } from '@angular/material';
import { UserIdleModule } from 'angular-user-idle';

import { RoutingModule } from './modules/routing.module';
import { MaterialModule } from './modules/material.module';
import { TranslationModule } from './modules/translate.module';
import { HttpInterceptorProviders } from './http-interceptors';

import { AppComponent } from './app.component';
import { HeaderComponent } from './components/shared/header/header.component';
import { SignupComponent } from './components/shared/signup/signup.component';
import { SigninComponent } from './components/shared/signin/signin.component';
import { ReportViewComponent } from './components/shared/report-view/report-view.component';
import { LoadingComponent } from './components/shared/loading/loading.component';
import { DocumentsViewComponent } from './components/shared/documents-view/documents-view.component';
import { FileViewComponent } from './components/shared/file-view/file-view.component';
import { FileSliderComponent } from './components/shared/file-slider/file-slider.component';
import { HomeComponent } from './components/home/home.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { PatientProfileComponent } from './components/shared/patient-profile/patient-profile.component';
import { DoctorListComponent } from './components/shared/doctor-list/doctor-list.component';
import { DoctorTimeSlotComponent } from './components/shared/doctor-time-slot/doctor-time-slot.component';
import { AppointmentBookComponent } from './components/shared/appointment-book/appointment-book.component';
import { AppointmentCancelComponent } from './components/shared/appointment-cancel/appointment-cancel.component';
import { AppointmentRescheduleComponent } from './components/shared/appointment-reschedule/appointment-reschedule.component';
import { DoctorInfoComponent } from './components/shared/doctor-info/doctor-info.component';
import { AllUsersComponent } from './components/shared/all-users/all-users.component';
import { TeleconsultComponent } from './components/shared/teleconsult/teleconsult.component';
import { DragDropDirective } from './directives/drag-drop-directive';
import { FileUploadComponent } from './components/shared/file-upload/file-upload.component';
import { ContactusComponent } from './components/shared/contactus/contactus.component';
import { SafeHtmlPipe } from './pipes/safe-html/safe-html.pipe';
import { IdleTimeoutComponent } from './components/shared/idle-timeout/idle-timeout.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    ReportViewComponent,
    LoadingComponent,
    DocumentsViewComponent,
    FileViewComponent,
    FileSliderComponent,
    HomeComponent,
    SignupComponent,
    SigninComponent,
    DashboardComponent,
    PatientProfileComponent,
    DoctorListComponent,
    DoctorTimeSlotComponent,
    AppointmentBookComponent,
    AppointmentCancelComponent,
    AppointmentRescheduleComponent,
    DoctorInfoComponent,
    AllUsersComponent,
    TeleconsultComponent,
    DragDropDirective,
    FileUploadComponent,
    ContactusComponent,
    SafeHtmlPipe,
    IdleTimeoutComponent
  ],
  entryComponents: [
    SignupComponent,
    SigninComponent,
    ReportViewComponent,
    LoadingComponent,
    DocumentsViewComponent,
    FileViewComponent,
    DoctorTimeSlotComponent,
    AppointmentBookComponent,
    AppointmentCancelComponent,
    AppointmentRescheduleComponent,
    DoctorInfoComponent,
    AllUsersComponent,
    TeleconsultComponent,
    FileUploadComponent,
    ContactusComponent,
    IdleTimeoutComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    InfiniteScrollModule,
    RoutingModule,
    FlexLayoutModule,
    MaterialModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatRippleModule,
    TranslationModule,
    UserIdleModule.forRoot({ 
      idle: 60 * 60, 
      timeout: 0, 
      ping: null 
    })
  ],
  providers: [HttpInterceptorProviders],
  bootstrap: [AppComponent]
})
export class AppModule { }