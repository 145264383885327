import { Component, ViewEncapsulation, Input, OnInit } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { DocumentsViewComponent } from '../documents-view/documents-view.component';
import { FileViewComponent } from '../file-view/file-view.component';
import { ReportService } from 'src/app/services/report/report.service';
import { PatientService } from '../../../services/patient/patient.service';

@Component({
  selector: 'pp-file-slider',
  templateUrl: './file-slider.component.html',
  styleUrls: ['./file-slider.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FileSliderComponent implements OnInit {

  @Input() files: any[] = [];

  constructor (
    private dialog: MatDialog,
    private snackbar: MatSnackBar,
    private reportService: ReportService,
    private patientService: PatientService
  ) { }

  ngOnInit() { }

  base64ToArrayBuffer(base64) {
    const binary = window.atob(base64);
    const bytes = new Uint8Array(binary.length);
    for (let i = 0; i < binary.length; i++) bytes[i] = binary.charCodeAt(i);
    return bytes;
  };

  binaryToArrayBuffer(binary) {
    const bytes = new Uint8Array(binary.length);
    for (let i = 0; i < binary.length; i++) bytes[i] = binary[i];
    return bytes;
  }

  fileClick(data: any): void {
    if (data.isAttachment) {
      data.loading = true;
      this.reportService.getLabDocuments({ labresultuid: data.labresultuid }).subscribe(res => {
        const documents = (res || []).filter(e => e && e.document);
        documents.forEach(e => {
          const dataArray = e.document.split(';base64,');
          const base64String = dataArray[1];
          const contentType = dataArray[0].split('data:')[1];
          if (base64String && contentType) {
            const blob = new Blob([this.base64ToArrayBuffer(base64String)], { type: contentType });
            window.open(URL.createObjectURL(blob));
          }
        });
        data.loading = false;
      });
    } else if (data.isForm) {
      data.loading = true;
      this.reportService.getFormDocuments({ formuid: data.formuid, orguid: data.orguid }).subscribe(res => {
        if (res.data) {
          const blob = new Blob([this.binaryToArrayBuffer(res.data)], { type: res.type });
          window.open(URL.createObjectURL(blob));
        }
        data.loading = false;
      });
    } else if (data.isCert) {
      data.loading = true;
      this.reportService.getCertDocuments({ certuid: data.certuid, orguid: data.orguid }).subscribe(res => {
        if (res.data) {
          const blob = new Blob([this.binaryToArrayBuffer(res.data)], { type: res.type });
          window.open(URL.createObjectURL(blob));
        }
        data.loading = false;
      });
    } else {
      this.patientService.getPatientReportById(data._id).subscribe(res => {
        fetch(res.file).then(res => res.blob()).then(blob => {
          window.open(URL.createObjectURL(blob));
        }, _ => {
          this.snackbar.open('File Error', '', {
            panelClass: ['pp-snackbar-err'],
            verticalPosition: 'top',
            horizontalPosition: 'right',
            duration: 5000
          });
        });
      });
    }
  }

  viewDocs(event: MouseEvent, data: any): void {
    event.stopPropagation();
    this.dialog.open(DocumentsViewComponent, {
      minWidth: 'calc(100vw - 100px)',
      minHeight: 'calc(100vh - 100px)',
      panelClass: 'pp-documents-view',
      disableClose: true,
      data: data
    });
  }

  downloadDocs(event: MouseEvent, data: any): void {
    event.stopPropagation();
    if (data.isAttachment) {
      data.downloading = true;
      this.reportService.getLabDocuments({ labresultuid: data.labresultuid }).subscribe(res => {
        const a = document.createElement('a');
        const documents = (res || []).filter(e => e && e.document);
        documents.forEach(e => {
          a.href = e.document;
          a.download = e.documentname;
          a.click();
        });
        data.downloading = false;
      });
    } else if (data.isForm) {
      data.downloading = true;
      this.reportService.getFormDocuments({ formuid: data.formuid, orguid: data.orguid }).subscribe(res => {
        if (res.data) {
          const a = document.createElement('a');
          const blob = new Blob([this.binaryToArrayBuffer(res.data)], { type: res.type });
          a.href = URL.createObjectURL(blob);
          a.download = res.name;
          a.click();
        }
        data.downloading = false;
      });
    } else if (data.isCert) {
      data.downloading = true;
      this.reportService.getCertDocuments({ certuid: data.certuid, orguid: data.orguid }).subscribe(res => {
        if (res.data) {
          const a = document.createElement('a');
          const blob = new Blob([this.binaryToArrayBuffer(res.data)], { type: res.type });
          a.href = URL.createObjectURL(blob);
          a.download = res.name;
          a.click();
        }
        data.downloading = false;
      });
    }
  }

  viewClick(event: MouseEvent, fileId: string): void {
    event.stopPropagation();
    this.dialog.open(FileViewComponent, {
      minWidth: 'calc(100vw - 100px)',
      minHeight: 'calc(100vh - 100px)',
      panelClass: 'pp-file-view',
      disableClose: true,
      data: fileId
    });
  }

  downloadClick(event: MouseEvent, fileId: string): void {
    event.stopPropagation();
    this.patientService.getPatientReportById(fileId).subscribe(res => {
      const a = document.createElement('a');
      a.href = res.file;
      a.download = res.filename || res.documentname;
      a.click();
    });
  }

}