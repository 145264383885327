import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(private http: HttpClient) { }

  getLabDocuments(data): Observable<any> {
    return this.http.post<any>('/api/report/lab/documents', data);
  }

  getFormDocuments(data): Observable<any> {
    return this.http.post<any>('/api/report/form/documents', data);
  }

  getCertDocuments(data): Observable<any> {
    return this.http.post<any>('/api/report/cert/documents', data);
  }
}
